import * as React from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Home from '../pages/home'
import Company from '../pages/company'
import Service from '../pages/service'
import Work from '../pages/work'
import WorkDetail from '../pages/work/detail'
import News from '../pages/news'
import Contact from '../pages/contact'
import logo_wh from '../assets/img/logo_wh.png'
import logo_bk from '../assets/img/logo_bk.png'
import { HeaderWrapper } from '../styles/layout.style'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import MobileNav from './navigation/mobileNav'
import PcNav from './navigation/pcNav'

interface IHeaderProps {}
interface IHeaderState {
  scrollStyle: string
  hoverStyle: string
  clickStyle: string
  scrollLogo: any
  drawerVisible: boolean
}

export default class header extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props)
    this.state = {
      scrollStyle: '',
      hoverStyle: '',
      clickStyle: '',
      scrollLogo: logo_wh,
      drawerVisible: false,
    }
  }

  mobileSubNavHandler = () => {
    this.setState({ drawerVisible: false })
    window.scrollTo(0, 0)
  }
  navClickHandler = () => {
    this.setState({ clickStyle: 'clickStyle', hoverStyle: '' })
    window.scrollTo(0, 0)
  }
  mouseEnterHandler = () => this.setState({ hoverStyle: 'hoverStyle', scrollLogo: logo_bk })
  mouseLeaveHandler = () => {
    window.scrollY < 40
      ? this.setState({ hoverStyle: '', scrollLogo: logo_wh })
      : this.setState({ hoverStyle: '', scrollLogo: logo_bk })
  }
  scrollStyleHandler = () => {
    window.scrollY < 40
      ? this.setState({ scrollStyle: '', scrollLogo: logo_wh })
      : this.setState({ scrollStyle: 'scrollStyle', scrollLogo: logo_bk })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollStyleHandler)
  }

  render() {
    return (
      <div>
        <HeaderWrapper>
          <div className={`pc ${this.state.scrollStyle} ${this.state.hoverStyle}`}>
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={this.state.scrollLogo} alt="퍼플시드 로고" />
            </Link>
            <PcNav
              navClickHandler={this.navClickHandler}
              mouseEnterHandler={this.mouseEnterHandler}
              mouseLeaveHandler={this.mouseLeaveHandler}
            />
          </div>
          <div className={`mobile ${this.state.scrollStyle}`}>
            <div className="mobileWrap">
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <img src={this.state.scrollLogo} alt="퍼플시드 로고" />
              </Link>
              <div
                className="menuBox"
                onClick={() => {
                  this.setState({ drawerVisible: true })
                }}
              >
                <FontAwesomeIcon className="menuBar" icon={faBars} />
              </div>
            </div>
          </div>
        </HeaderWrapper>
        <MobileNav drawerVisible={this.state.drawerVisible} mobileSubNavHandler={this.mobileSubNavHandler} />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/company" component={Company} />
          <Route path="/service" component={Service} />
          <Route path="/work" component={Work} />
          <Route path="/work_detail/:id" component={WorkDetail} />
          <Route path="/news" component={News} />
          <Route path="/contact" component={Contact} />
        </Switch>
      </div>
    )
  }
}
