import * as React from 'react'
import { WorkDetailCardWrapper } from '../styles/card.style'
import AppBtn from './appBtn'
import WebBtn from './webBtn'

interface IWorkDetailCardProps {
  pcTopBanner: string
  mbTopBanner: string
  title: string
  client: string
  launch: string
  descript: string
  pcDetailImg: string
  mbDetailImg: string
  category: string
  system: string
  video: string
  video2: string
  googleLink: string
  appleLink: string
  webLink: string
}

export default class workDetailCard extends React.Component<IWorkDetailCardProps> {
  constructor(props: IWorkDetailCardProps) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <WorkDetailCardWrapper>
        {this.props.pcTopBanner && (
          <div className="wrap">
            <img className="topBanner pcImg" src={this.props.pcTopBanner} alt="이미지" />
            <img className="topBanner mobileImg" src={this.props.mbTopBanner} alt="이미지" />
          </div>
        )}
        {this.props.title && (
          <div className="wrap" style={{ paddingTop: 0 }}>
            <h2>{this.props.title}</h2>
            <div className="subTitle">
              <h3>
                Client <span>{this.props.client}</span>
              </h3>
              <h3>
                Launch <span>{this.props.launch}</span>
              </h3>
            </div>
            <p>{this.props.descript}</p>
          </div>
        )}
        {this.props.video && (
          <div className="videoWrapper wrap">
            <iframe title="YouTube video player" src={this.props.video} frameBorder="0" />
          </div>
        )}
        {this.props.video2 && (
          <div className="videoWrapper wrap">
            <iframe title="YouTube video player" src={this.props.video2} frameBorder="0" />
          </div>
        )}
        {this.props.pcDetailImg && (
          <>
            <img className="pcImg" src={this.props.pcDetailImg} alt="이미지" />
            <img className="mobileImg" src={this.props.mbDetailImg} alt="이미지" />
          </>
        )}
        <div className="btnWrapper">
          {this.props.system === 'app' ? (
            <AppBtn googleLink={this.props.googleLink} appleLink={this.props.appleLink} />
          ) : this.props.system === 'web' ? (
            <WebBtn webLink={this.props.webLink} title={this.props.title} />
          ) : null}
        </div>
      </WorkDetailCardWrapper>
    )
  }
}
