import * as React from 'react'
import { Link } from 'react-router-dom'
import { CardWrapper } from '../styles/card.style'
import { DefaultButton } from './button'

export interface ICardRightProps {
  subTitle: string
  title: string
  descript: string
  linkTxt: string
  img: string
  link: string
}

export default class cardRight extends React.Component<ICardRightProps> {
  constructor(props: ICardRightProps) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <CardWrapper className="cardRight">
        <div className="txtWrap">
          <h3>{this.props.subTitle}</h3>
          <h2>{this.props.title}</h2>
          <p>{this.props.descript}</p>
          <Link to={this.props.link} onClick={() => window.scrollTo(0, 0)}>
            <DefaultButton text={this.props.linkTxt} />
          </Link>
        </div>
        <div className="imgWrap">
          <img src={this.props.img} alt="이미지" />
        </div>
      </CardWrapper>
    )
  }
}
