import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  HomeWrapper,
  PcMainBanner,
  MobileMainBanner,
  Card,
  AboutImg,
  WhatWeDo,
  ListWrap,
  WhatWeMade,
  Clients,
} from '../../styles/home.style'
import CardLeft from '../../components/cardLeft'
import CardRight from '../../components/cardRight'
import marketing_main from '../../assets/img/marketing_main.png'
import contents_main from '../../assets/img/contents_main.png'
import consulting_main from '../../assets/img/consulting_main.png'
import news_main from '../../assets/img/news_main.png'
import main_slide from '../../assets/img/main_slider.png'
import mobile_main_slide from '../../assets/img/mobile_main_slider.jpg'
import about_us from '../../assets/img/about_us.jpg'
import clients from '../../json/clients.json'
import { DefaultButton } from '../../components/button'
import NoticePopup from '../../components/noticePopup'
import noticeImage from '../../assets/notice.png'


export interface IHomeProps {}

export default class Home extends React.Component<IHomeProps> {
  public render() {
    return (
      <HomeWrapper>
        {/* <NoticePopup width={600} src={noticeImage} alt={"공지사항"}></NoticePopup>  */}
        
        <PcMainBanner src={main_slide} alt="pc 메인배너 이미지" />
        <MobileMainBanner src={mobile_main_slide} alt="모바일 메인배너 이미지" />
        <Card className="wrap">
          <div className="cardImgWrap">
            <AboutImg src={about_us} alt="회사 소개 이미지" />
          </div>
          <div className="cardTxtWrap">
            <h3>About us</h3>
            <h2>{`차별성을 가진 보라,\n 가능성을 가진 씨앗`}</h2>
            <p>
              퍼플시드의 에너지 씨앗이 고객님들의 성공에
              <br />
              든든한 버팀목이 되는 보랏빛 숲으로 자리하겠습니다.
            </p>
            <Link to="/company" onClick={() => window.scrollTo(0, 0)}>
              <DefaultButton text="More View" />
            </Link>
          </div>
        </Card>
        <WhatWeDo>
          <div className="wrap">
            <h3>WHAT WE DO</h3>
            <h2>퍼플시드 서비스 영역</h2>
            <p>
              퍼플시드는 트렌드에 맞추어 고객의 다양한 요구와 소비자 니즈를 반영하여
              <br />
              고객사의 비전에 맞게 최적화된 마케팅 및 컨설팅 서비스를 제공합니다.
            </p>
            <ListWrap>
              <CardLeft
                img={marketing_main}
                subTitle="Marketing"
                title="온/오프라인 마케팅"
                descript={`퍼플시드는 고객사의 비전에 맞게\n최상의 결과를 이끌어냅니다.`}
                linkTxt="Marketing"
                link="/service?tab=marketing"
              />
              <CardRight
                img={contents_main}
                subTitle="Contents"
                title="콘텐츠 기획/제작/배포"
                descript={`퍼플시드는 고객의 니즈를 반영한\n창의적인 콘텐츠를 제작, 배포합니다.`}
                linkTxt="Contents"
                link="/service?tab=contents"
              />
              <CardLeft
                img={consulting_main}
                subTitle="Consulting"
                title={`비지니스/브랜드\n문서 컨설팅/인적 자원`}
                descript={`퍼플시드는 고객의 사업 시작과\n운영에 관한\n비즈니스 컨설팅을 제공합니다.`}
                linkTxt="Consulting"
                link="/service?tab=consulting"
              />
            </ListWrap>
          </div>
        </WhatWeDo>
        <WhatWeMade>
          <div className="wrap">
            <h2>WHAT WE MADE</h2>
            <h3>퍼플시드 프로젝트</h3>
            <h4>{`퍼플시드는 디지털 마케팅 전략 수립과 실행, 제작까지\n 디지털 마케팅의 모든 서비스를 제공합니다.`}</h4>
            <Link to="/work" onClick={() => window.scrollTo(0, 0)}>
              More view
            </Link>
          </div>
        </WhatWeMade>
        <Card className="wrap card2">
          <div className="cardTxtWrap">
            <h3>News</h3>
            <h2>
              Find out the latest news
              <br />
              about PURPLESEED
            </h2>
            <p>퍼플시드의 새로운 소식과 함께 하세요!</p>
            <Link to="/news" onClick={() => window.scrollTo(0, 0)}>
              <DefaultButton text="News" />
            </Link>
          </div>
          <div className="cardImgWrap">
            <img src={news_main} alt="이미지" />
          </div>
        </Card>
        <Clients>
          <div className="wrap">
            <h2>OUR CLIENTS</h2>
            <div className="imgWrapper">
              {clients.map((img, i) => {
                return (
                  <div className="imgWrap" key={`clientsKey: ${i.toString()}`}>
                    <img src={process.env.PUBLIC_URL + img.url} alt="클라이언트 이미지" />
                  </div>
                )
              })}
            </div>
          </div>
        </Clients>
      </HomeWrapper>
    )
  }
}
