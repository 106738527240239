import * as React from 'react'
import styled from 'styled-components'

interface INewsCardProps {
  title: string
  date: string
  link: string
}

export default class NewsCard extends React.Component<INewsCardProps> {
  constructor(props: INewsCardProps) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <NewsCardWrapper target="_blank" href={this.props.link}>
        <h2>{this.props.title}</h2>
        <h3>{this.props.date}</h3>
      </NewsCardWrapper>
    )
  }
}

const NewsCardWrapper = styled.a`
  width: 360px;
  height: 192px;
  border-top: 1px solid #707070;

  @media (max-width: 1230px) {
    width: 100%;
  }

  h2 {
    margin: 33px 0 27px;
    font-size: 28px;
    font-weight: 500;
    color: #111;

    @media (max-width: 414px) {
      margin: 30px 0 25px;
      font-size: 24px;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 400;
    color: #a3a3ae;
  }
`
