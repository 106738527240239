import * as React from 'react'
import styled from 'styled-components'
import purple_arrow from '../assets/img/arrow_purple_right.png'
import gray_arrow from '../assets/img/arrow_gray_right.png'
import white_arrow from '../assets/img/arrow_white_right.png'

export interface IButtonProps {
  text: String
}

export function WhiteButton({ text }: IButtonProps) {
  return (
    <WhiteButtonWrapper>
      {text}
      <img src={purple_arrow} alt="arrow icon" />
    </WhiteButtonWrapper>
  )
}

export function DefaultButton({ text }: IButtonProps) {
  return (
    <DefaultButtonWrapper>
      {text}
      <img className="arrow grayArrow" src={gray_arrow} alt="arrow icon" />
      <img className="arrow whiteArrow" src={white_arrow} alt="arrow icon" />
    </DefaultButtonWrapper>
  )
}

const WhiteButtonWrapper = styled.button`
  width: 180px;
  height: 45px;
  padding: 0 25px;
  text-align: center;
  padding: 0;
  margin: 45px auto 0;
  color: #641d87;
  border: none;
  border-radius: 45px;
  background: #fff;
  line-height: 47px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease-in;

  @media (max-width: 414px) {
    width: 133px;
    height: 40px;
    line-height: 42px;
    margin-top: 29px;
    font-size: 13px;
    text-align: start;
    padding-left: 20px;
  }

  img {
    position: relative;
    top: -2.4px;
    left: 5px;
    opacity: 0;
    transition: all 0.3s ease-in;

    @media (max-width: 414px) {
      width: 5px;
      left: 40px;
      opacity: 1;
    }
  }

  &:hover {
    padding-right: 20px;

    @media (max-width: 414px) {
      padding-right: 0;
    }
  }

  &:hover img {
    left: 30px;
    opacity: 1;

    @media (max-width: 414px) {
      left: 40px;
    }
  }
`
const DefaultButtonWrapper = styled.button`
  position: relative;
  width: 180px;
  height: 45px;
  padding: 0 25px;
  text-align: start;
  border: 1px solid #9e9e9e;
  border-radius: 45px;
  background: #fff;
  line-height: 47px;
  font-size: 16px;
  font-weight: 600;
  color: #111;
  transition: all 0.2s ease-in;

  @media (max-width: 414px) {
    width: 133px;
    height: 40px;
    line-height: 42px;
    margin-top: 29px;
    font-size: 13px;
  }

  .arrow {
    position: absolute;
    top: 18px;
    right: 25px;
    transition: 0.4s ease-in;

    @media (max-width: 414px) {
      top: 15.5px;
    }
  }

  .whiteArrow {
    display: none;
  }

  &:hover {
    background: #641d87;
    color: #fff;

    .grayArrow {
      display: none;
    }
    .whiteArrow {
      display: block;
    }
  }
`
