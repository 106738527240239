import * as React from 'react'
import styled from 'styled-components'

interface IWebBtnProps {
  title: string
  webLink: string
}

export default class WebBtn extends React.Component<IWebBtnProps> {
  public render() {
    return (
      <WebBtnWrapper>
        <div>{this.props.title}</div>
        <a href={this.props.webLink} rel="noreferrer" target="_blank">
          사이트 바로가기
        </a>
      </WebBtnWrapper>
    )
  }
}

const WebBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 140px;

  @media (max-width: 858px) {
    flex-direction: column;
  }

  div {
    margin-right: 100px;
    font-size: 25px;
    font-weight: 700;

    @media (max-width: 858px) {
      margin-right: 0;
      margin-bottom: 45px;
    }
    @media (max-width: 414px) {
      font-size: 16px;
    }
  }

  a {
    width: 260px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    color: #000;
    font-size: 18px;
    font-weight: 700;

    @media (max-width: 414px) {
      width: 200px;
      height: 46px;
      line-height: 46px;
      font-size: 14px;
    }

    &:hover {
      border: 1px solid #a3a3ae;
    }
  }
`
