import * as React from 'react'
import { Link } from 'react-router-dom'
import { WorkCardWrapper } from '../styles/card.style'

interface IWorkCardProps {
  id: any
  thumbnail: string
  client: string
  project: string
}

export default class workCard extends React.Component<IWorkCardProps> {
  constructor(props: IWorkCardProps) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <WorkCardWrapper>
        <Link to={`/work_detail/${this.props.id}`} onClick={() => window.scrollTo(0, 0)}>
          <div className="thumbnailWrap">
            <img src={process.env.PUBLIC_URL + this.props.thumbnail} alt="프로젝트 이미지" />
          </div>
          <h2>{this.props.client}</h2>
          <h3>{this.props.project}</h3>
        </Link>
      </WorkCardWrapper>
    )
  }
}
