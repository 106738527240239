import styled from 'styled-components'
import contact_banner from '../assets/img/contact_banner.png'

export const ContactBanner = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: url(${contact_banner}) no-repeat center;
  background-size: cover;

  @media (max-width: 414px) {
    height: 250px;
  }

  h2 {
    text-align: center;
    line-height: 420px;
    font-size: 45px;
    font-weight: bold;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 414px) {
      font-size: 30px;
      line-height: 280px;
    }
  }
`

export const ContactWrapper = styled.div`
  h3 {
    color: #151515;
    font-size: 37px;
    line-height: 32px;
    margin: 10px 0 20px;
    letter-spacing: 1px;

    @media (max-width: 414px) {
      font-size: 28px;
    }
  }
  h4 {
    margin-bottom: 30px;
    color: #151515;
    font-size: 16px;
  }
  ul {
    display: flex;
    margin-bottom: 80px;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    li {
      margin-right: 20px;
      color: #151515;
      font-size: 16px;

      span {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
`

export const Map = styled.div`
  width: 100%;
  height: 550px;
  background: #ebebeb;

  @media (max-width: 768px) {
    height: 450px;
  }
  @media (max-width: 414px) {
    height: 350px;
  }
`

export const WithUs = styled.div`
  width: 100%;
  padding: 50px 0;
  background: #eaeaea;

  @media (max-width: 1230px) {
    padding: 50px 30px;
  }
  @media (max-width: 414px) {
    padding: 50px 15px;
  }

  .withusWrap {
    padding: 50px 150px;
    background: #fff;

    @media (max-width: 1230px) {
      padding: 50px;
    }
    @media (max-width: 414px) {
      padding: 50px 20px;
    }

    h5 {
      margin-bottom: 50px;
      font-size: 2.5rem;

      @media (max-width: 768px) {
        font-size: 30px;
      }
      @media (max-width: 414px) {
        font-size: 24px;
      }
    }

    .inputBox {
      display: inline-block;
      width: 44%;
      padding: 10px 5px;
      margin-bottom: 50px;

      input {
        width: 100%;
        margin-bottom: 10px;
        padding: 8px 5px;
        border: none;
        border-bottom: 1px solid #bababa;
      }
      p {
        position: absolute;
        margin-bottom: 0;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      &:nth-of-type(2n + 1) {
        margin-right: 10%;

        @media (max-width: 600px) {
          margin-right: 0;
        }
      }
    }
    #message {
      width: 100%;
      border: 0;
      padding: 8px 5px;
      border-bottom: 1px solid #bababa;
    }

    button {
      width: 180px;
      height: 45px;
      padding: 0 25px;
      text-align: center;
      padding: 0;
      margin: 45px auto 0;
      color: #fff;
      border: none;
      border-radius: 45px;
      background: #641d87;
      line-height: 47px;
      font-size: 16px;
      font-weight: 600;
      transition: all 0.3s ease-in;

      &:disabled {
        background: #454545;
        font-size: 15px;

        @media (max-width: 414px) {
          font-size: 13px;
        }
      }

      @media (max-width: 414px) {
        width: 133px;
        height: 40px;
        line-height: 42px;
        margin-top: 29px;
        font-size: 13px;
        text-align: start;
        padding-left: 20px;
      }

      img {
        position: relative;
        top: -2.4px;
        left: 5px;
        opacity: 0;
        transition: all 0.3s ease-in;

        @media (max-width: 414px) {
          width: 5px;
          left: 40px;
          opacity: 1;
        }
      }

      &:hover {
        padding-right: 20px;
        color: #fff;
        background: #641d87;

        @media (max-width: 414px) {
          padding-right: 0;
        }
      }

      &:hover img {
        left: 30px;
        opacity: 1;

        @media (max-width: 414px) {
          left: 40px;
        }
      }
    }
  }

  button[disabled]:hover {
    background: #454545;
    padding-right: 0;
    cursor: default;
  }
  button[disabled]:hover img {
    opacity: 0;
  }

  .ant-spin-dot-item {
    background-color: #fff;
  }
`
