import * as React from 'react'
import { ContactWrapper, ContactBanner, Map, WithUs } from '../../styles/contact.style'
import white_arrow from '../../assets/img/arrow_white_right.png'
import { Spin } from 'antd'

interface IContactProps {}
interface IContactStates {
  name: string
  company: string
  phone: string
  email: string
  message: string
  nameValidTxt: string
  companyValidTxt: string
  phoneValidTxt: string
  emailValidTxt: string
  messageValidTxt: string
  nameValidColor: string
  companyValidColor: string
  messageValidColor: string
  emailValidColor: string
  phoneValidColor: string
  loading: boolean
}

const emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/

export default class contact extends React.Component<IContactProps, IContactStates> {
  constructor(props: IContactProps) {
    super(props)
    this.state = {
      name: '',
      company: '',
      phone: '',
      email: '',
      message: '',
      nameValidTxt: '',
      companyValidTxt: '',
      phoneValidTxt: '',
      emailValidTxt: '',
      messageValidTxt: '',
      nameValidColor: '',
      companyValidColor: '',
      messageValidColor: '',
      emailValidColor: '',
      phoneValidColor: '',
      loading: false,
    }
  }

  init = () => {
    this.setState({
      name: '',
      company: '',
      phone: '',
      email: '',
      message: '',
      nameValidTxt: '',
      companyValidTxt: '',
      phoneValidTxt: '',
      emailValidTxt: '',
      messageValidTxt: '',
    })
  }

  nameInputHandler = (e: any) => {
    this.setState({ [e.target.name]: e.target.value } as React.ComponentState)
    if (e.target.value.length < 2) {
      this.setState({ nameValidTxt: '2글자 이상 작성해주세요.', nameValidColor: '#ff0000' })
    } else {
      this.setState({ nameValidTxt: '올바른 형식입니다.', nameValidColor: '#008000' })
    }
  }
  companyInputHandler = (e: any) => {
    this.setState({ [e.target.name]: e.target.value } as React.ComponentState)
    if (e.target.value.length < 2) {
      this.setState({ companyValidTxt: '2글자 이상 작성해주세요.', companyValidColor: '#ff0000' })
    } else {
      this.setState({ companyValidTxt: '올바른 형식입니다.', companyValidColor: '#008000' })
    }
  }
  phoneInputHandler = (e: any) => {
    this.setState({ [e.target.name]: e.target.value } as React.ComponentState)
    if (!e.target.value.match(phoneRegExp)) {
      this.setState({ phoneValidTxt: '전화번호 형식에 맞게 작성해주세요.', phoneValidColor: '#ff0000' })
    } else {
      this.setState({ phoneValidTxt: '올바른 전화번호 형식입니다.', phoneValidColor: '#008000' })
    }
  }
  emailInputHandler = (e: any) => {
    this.setState({ [e.target.name]: e.target.value } as React.ComponentState)
    if (!e.target.value.match(emailRegExp)) {
      this.setState({ emailValidTxt: '이메일 형식에 맞게 작성해주세요.', emailValidColor: '#ff0000' })
    } else {
      this.setState({ emailValidTxt: '올바른 이메일 형식입니다.', emailValidColor: '#008000' })
    }
  }
  messageInputHandler = (e: any) => {
    this.setState({ [e.target.name]: e.target.value } as React.ComponentState)
    if (e.target.value.length < 20) {
      this.setState({ messageValidTxt: '20글자 이상 작성해주세요.', messageValidColor: '#ff0000' })
    } else {
      this.setState({ messageValidTxt: '올바른 형식입니다.', messageValidColor: '#008000' })
    }
  }

  sendEmailHandler = () => {
    if (
      this.state.name.length >= 2 &&
      this.state.company.length >= 2 &&
      this.state.phone.match(phoneRegExp) &&
      this.state.email.match(emailRegExp) &&
      this.state.message.length >= 20
    ) {
      this.setState({ loading: true })
      fetch(process.env.PUBLIC_URL + 'send.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.state),
      })
        .then((res) => {
          res.json().then((data) => {
            if (data.success) {
              window.dataLayer.push({
                event: 'contactFormSubmit',
                Corp_Name: this.state.company,
              })
              this.setState({ loading: false })
              alert('문의사항이 성공적으로 제출되었습니다.')
              this.init()
            } else {
              this.setState({ loading: false })
              alert('문의사항 제출에 실패하였습니다.')
              this.init()
              console.log(data)
              console.log(res)
            }
          })
        })
        .catch((err) => {
          this.setState({ loading: false })
          console.error('Error:', err)
        })
    } else {
      alert('문의사항을 다시 입력해주세요.')
    }
  }

  KakaoMapScript = () => {
    const container = document.getElementById('myMap')
    const options = {
      center: new window.kakao.maps.LatLng(37.5119106, 127.021357),
      level: 3,
    }
    var map = new window.kakao.maps.Map(container, options)
    var imageSrc = process.env.PUBLIC_URL + '/public_assets/img/contact/placeholder.png',
      imageSize = new window.kakao.maps.Size(64, 69),
      imageOption = { offset: new window.kakao.maps.Point(27, 69) }
    var markerImage = new window.kakao.maps.MarkerImage(imageSrc, imageSize, imageOption),
      markerPosition = new window.kakao.maps.LatLng(37.5119106, 127.021357)
    var marker = new window.kakao.maps.Marker({
      position: markerPosition,
      image: markerImage,
    })
    marker.setMap(map)
    var content = `<div class="customoverlay" style="margin-bottom:75px;margin-left: 7px;background:#fff; padding: 5px 20px; border-radius:5px;">
        <span class="title" style="color:#641d87;font-weight:bold;font-size: 16px;">퍼플시드</span>
      </div>`
    var position = new window.kakao.maps.LatLng(37.5119106, 127.021357)
    new window.kakao.maps.CustomOverlay({
      map: map,
      position: position,
      content: content,
      yAnchor: 1,
    })
  }

  componentDidMount() {
    this.KakaoMapScript()
  }

  render() {
    return (
      <ContactWrapper>
        <ContactBanner>
          <h2>Contact us</h2>
        </ContactBanner>
        <div className="wrap">
          <h3>CONTACT US</h3>
          <h4>서울특별시 강남구 논현동 50, 삼익전자 빌딩 7F</h4>
          <ul>
            <li>
              <span>T </span> 02.6080.0920
            </li>
            <li>
              <span>E </span> purpleseed@purpleseed.co.kr
            </li>
          </ul>
          <Map id="myMap"></Map>
        </div>
        <WithUs>
          <div className="wrap withusWrap">
            <h5>퍼플시드와 함께 시작해볼까요?</h5>
            <div className="form">
              <div className="inputBox">
                <input
                  value={this.state.name}
                  onChange={this.nameInputHandler}
                  name="name"
                  id="name"
                  type="text"
                  placeholder="이름/직책"
                  required
                />
                <p style={{ color: this.state.nameValidColor }}>{this.state.nameValidTxt}</p>
              </div>
              <div className="inputBox">
                <input
                  value={this.state.company}
                  onChange={this.companyInputHandler}
                  name="company"
                  id="company"
                  type="text"
                  placeholder="기업/기관명"
                  required
                />
                <p style={{ color: this.state.companyValidColor }}>{this.state.companyValidTxt}</p>
              </div>
              <div className="inputBox">
                <input
                  value={this.state.phone}
                  onChange={this.phoneInputHandler}
                  name="phone"
                  id="phone"
                  type="tel"
                  placeholder="전화번호"
                  required
                />
                <p style={{ color: this.state.phoneValidColor }}>{this.state.phoneValidTxt}</p>
              </div>
              <div className="inputBox">
                <input
                  value={this.state.email}
                  onChange={this.emailInputHandler}
                  name="email"
                  id="email"
                  type="email"
                  placeholder="이메일"
                  required
                />
                <p style={{ color: this.state.emailValidColor }}>{this.state.emailValidTxt}</p>
              </div>
              <div>
                <textarea
                  value={this.state.message}
                  onChange={this.messageInputHandler}
                  name="message"
                  id="message"
                  placeholder="어떻게 도와드릴까요?"
                  required
                  rows={4}
                />
                <p style={{ color: this.state.messageValidColor }}>{this.state.messageValidTxt}</p>
              </div>
              <button onClick={this.sendEmailHandler} className="sbmBtn" disabled={this.state.loading ? true : false}>
                {this.state.loading ? (
                  <>
                    <Spin style={{ marginRight: 10 }} />
                    제출중입니다.
                  </>
                ) : (
                  '제출하기'
                )}
                <img className="arrow whiteArrow" src={white_arrow} alt="arrow icon" />
              </button>
            </div>
          </div>
        </WithUs>
      </ContactWrapper>
    )
  }
}
