import styled from 'styled-components'

export const HomeWrapper = styled.div`
  width: 100%;
`
export const PcMainBanner = styled.img`
  width: inherit;

  @media (max-width: 414px) {
    display: none;
  }
`
export const MobileMainBanner = styled.img`
  display: none;
  width: inherit;

  @media (max-width: 414px) {
    display: block;
  }
`
export const Card = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  .cardImgWrap {
    width: 600px;
    height: 600px;
    margin-right: 40px;
    overflow: hidden;

    @media (max-width: 1000px) {
      width: 100%;
      height: 400px;
      margin-right: 0;
    }
  }

  .cardTxtWrap {
    @media (max-width: 1000px) {
      width 100%;
      padding: 20px 0 0;
      text-align: center;
    }
    @media (max-width: 414px) {
      padding-top: 0;
    }
    h3 {
      line-height: 26px;
      letter-spacing: 1px;
      color: #641d87;
      font-size: 3.5rem;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 40px;
      }
      @media (max-width: 414px) {
        font-size: 28px;
      }
    }
    h2 {
      margin: 0 0 30px;
      letter-spacing: 1px;
      line-height: 32px;
      font-size: 26px;
      font-weight: bold;
      color: #332e54;

      @media (max-width: 414px) {
        white-space: pre-wrap;
        font-size: 22px;
      }
    }
    p {
      margin: 0 0 50px;
      line-height: 24px;
      font-size: 18px;
      color: #504b6e;

      @media (max-width: 414px){
        margin: 0;
      }
    }
    a {
      display: block;

      @media (max-width: 1000px) {
        margin: 0 auto;
      }
    }
  }

  &.card2 {
    .cardTxtWrap {
      margin: 0 60px;

      @media (max-width: 1000px) {
        margin-bottom: 60px;
      }
    }
    .cardImgWrap {
      width: 758px;
      height: auto;
      margin-right: 0;
      overflow: hidden;

      @media (max-width: 1000px) {
        width: 100%;
        margin-right: 0;
      }

      img {
        width: 758px;
        transition: transform 0.25s ease-in;

        @media (max-width: 1000px) {
          width: 100%;
        }

        &:hover {
          transform: scale(120%, 120%);
        }
      }
    }
  }
`
export const AboutImg = styled.img`
  width: 100%;
  transition: transform 0.25s ease-in;

  &:hover {
    transform: scale(120%, 120%);
  }
`
export const WhatWeDo = styled.div`
  width: 100%;
  background: #f8f9fb;

  h3 {
    text-align: center;
    color: #641d87;
    font-size: 52px;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 38px;
    }
    @media (max-width: 414px) {
      font-size: 28px;
    }
  }
  h2 {
    margin: 20px 0;
    text-align: center;
    font-size: 1.9rem;
    font-weight: 600;

    @media (max-width: 414px) {
      font-size: 22px;
    }
  }
  p {
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.3rem;

    @media (max-width: 414px) {
      font-size: 18px;
    }
  }
`
export const ListWrap = styled.div`
  width: 100%;
  background: #fff;
`
export const WhatWeMade = styled.div`
  width: 100%;
  background: #1b1b1b;

  h2 {
    text-align: center;
    font-size: 3.5rem;
    font-weight: 700;
    color: #fff;

    @media (max-width: 768px) {
      font-size: 40px;
    }
    @media (max-width: 414px) {
      font-size: 28px;
    }
  }
  h3 {
    margin: 20px 0;
    font-size: 1.9rem;
    font-weight: 600;
    text-align: center;
    color: #fff;

    @media (max-width: 414px) {
      font-size: 24px;
    }
  }
  h4 {
    margin-bottom: 50px;
    text-align: center;
    font-size: 1.3rem;
    color: #fff;

    @media (max-width: 768px) {
      white-space: pre-wrap;
    }
    @media (max-width: 414px) {
      font-size: 18px;
    }
  }
  a {
    font-size: 13px;
    font-weight: bold;
    width: 160px;
    text-align: center;
    margin: 0 auto;
    line-height: 50px;
    height: 50px;
    border-radius: 25px;
    box-sizing: border-box;
    display: block;
    border: 1px solid #fcf7ef;
    color: #fff;

    &:hover {
      background: #fff;
      color: #1b1b1b;
    }
  }
`
export const Clients = styled.div`
  width: 100%;
  background: #f8f9fb;

  h2 {
    margin-bottom: 50px;
    text-align: center;
    color: #641d87;
    font-size: 3.5rem;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 40px;
    }
    @media (max-width: 414px) {
      font-size: 28px;
    }
  }

  .imgWrapper {
    display: flex;
    flex-wrap: wrap;

    .imgWrap {
      margin: 10px auto;

      @media (max-width: 1000px) {
        width: 120px;
      }

      img {
        @media (max-width: 1000px) {
          width: 100%;
        }
      }
    }
  }
`
