import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { PcSubNavWrapper } from '../../styles/layout.style'

export interface IPcSubNavProps {
  clickHandler: any
}

const PcSubNav: React.FC<IPcSubNavProps> = ({ clickHandler }: IPcSubNavProps) => {
  return (
    <PcSubNavWrapper>
      <div>
        <NavLink to="/company?tab=company" onClick={clickHandler}>
          회사소개
        </NavLink>
        <NavLink to="/company?tab=brand" onClick={clickHandler}>
          브랜드소개
        </NavLink>
        <NavLink to="/company?tab=ceo" onClick={clickHandler}>
          ceo인사말
        </NavLink>
      </div>
      <div>
        <NavLink to="/service?marketing" onClick={clickHandler}>
          Marketing
        </NavLink>
        <NavLink to="/service?tab=contents" onClick={clickHandler}>
          Contents
        </NavLink>
        <NavLink to="/service?tab=digital_platform" onClick={clickHandler}>
          Digital Platform
        </NavLink>
        <NavLink to="/service?tab=consulting" onClick={clickHandler}>
          Consulting
        </NavLink>
      </div>
      <div>
        <NavLink to="/work?tab=2" onClick={clickHandler}>
          공기업/정부기관
        </NavLink>
        <NavLink to="/work?tab=3" onClick={clickHandler}>
          일반기업
        </NavLink>
      </div>
      <div>
        <NavLink to="/news?tab=news" onClick={clickHandler}>
          NEWS
        </NavLink>
        <NavLink to="/news?tab=pr_material" onClick={clickHandler}>
          PR MATERIALS
        </NavLink>
      </div>
      <div>
        <NavLink to="/contact" onClick={clickHandler}>
          CONTACT US
        </NavLink>
      </div>
    </PcSubNavWrapper>
  )
}

export default PcSubNav
