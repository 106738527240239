import styled from 'styled-components'
import { Tabs } from 'antd'
import work_banner from '../assets/img/work_banner.png'

export const WorkWrapper = styled.div`
  width: 100%;

  .ant-tabs-nav {
    position: absolute;
    top: 470px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 414px) {
      top: 250px;
      left: 0;
      transform: translateX(0);
      width: 100%;
      border-bottom: 1px solid #a3a3ae;
    }

    &::before,
    &::after {
      display: none;
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab {
    width: fit-content;
    margin: 0 70px;
    padding: 17px 0;

    @media (max-width: 768px) {
      margin: 0 24px;
    }
    @media (max-width: 414px) {
      margin: 0 20px;
    }

    &:hover {
      color: #a3a3ae;
    }
  }
  .ant-tabs-tab-btn {
    text-align: center;
    text-shadow: none;
    font-size: 18px;
    font-weight: 400;
    color: #a3a3ae;

    @media (max-width: 414px) {
      text-align: left;
      font-size: 14px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
    font-weight: 400;
    text-shadow: none;
    text-decoration: underline;
    border: none;
  }
`

export const WorkBanner = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: url(${work_banner}) no-repeat center;
  background-size: cover;

  @media (max-width: 414px) {
    height: 250px;
  }

  h2 {
    text-align: center;
    line-height: 420px;
    font-size: 45px;
    font-weight: bold;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 414px) {
      font-size: 30px;
      line-height: 280px;
    }
  }
`

export const TabTitle = styled.div`
  width: 100%;
  margin-bottom: 100px;
  text-align: center;
  font-size: 35px;
  color: #111;
  font-weight: 400;

  @media (max-width: 414px) {
    font-size: 26px;
    margin: 10px 0 15px;
  }
`

export const WorkLists = styled(Tabs)`
  margin: 0 auto !important;
  padding-top: 100px;

  @media (max-width: 414px) {
    padding-top: 60px;
  }

  .ant-list-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-items: center;

    @media (max-width: 920px) {
      display: flex;
      flex-direction: column;
    }
  }
  .ant-list-item {
    grid-column: 1;
    margin-bottom: 60px;
    border-bottom: none !important;

    @media (max-width: 1030px) {
      margin-bottom: 0;
      padding: 0;
    }

    &:nth-of-type(2),
    &:nth-of-type(5),
    &:nth-of-type(8) {
      grid-column: 2;
    }

    &:nth-of-type(3n) {
      grid-column: 3;
    }
  }
  .ant-pagination {
    margin-top: 0;
    text-align: center;

    @media (max-width: 1030px) {
      margin-top: 30px;
    }

    button {
      &:hover span {
        color: #000;
      }
      &:hover {
        border-color: #000;
      }
    }
  }
  .ant-pagination-item {
    &:hover a {
      color: #000;
    }
    &:hover {
      border-color: #000;
    }
  }
  .ant-pagination-options {
    display: none;
  }
  .ant-pagination-item-active a {
    color: #000;
  }
  .ant-pagination-item-active {
    border-color: #000;
  }
`

export const WorkDetailWrapper = styled.div`
  img {
    width: 100%;
  }
`

export const WorkDetailBanner = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: url(${work_banner}) no-repeat center;
  background-size: cover;

  @media (max-width: 414px) {
    height: 250px;
  }

  h2 {
    text-align: center;
    line-height: 420px;
    font-size: 45px;
    font-weight: bold;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 414px) {
      font-size: 30px;
      line-height: 280px;
    }
  }
`
