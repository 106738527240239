import * as React from 'react'
import { List } from 'antd'
import NewsCard from '../../components/newsCard'
import news from '../../json/news.json'
import { NewsTabBanner, NewsTabSection1, NewsTabWrapper, TabTitle } from '../../styles/news.style'

interface INewsTabProps {}

export default function NewsTab(props: INewsTabProps) {
  return (
    <NewsTabWrapper>
      <NewsTabBanner>
        <h2>NEWS</h2>
      </NewsTabBanner>
      <TabTitle>뉴스</TabTitle>
      <NewsTabSection1 className="wrap">
        <List
          dataSource={news}
          pagination={{
            pageSize: 9,
          }}
          itemLayout="horizontal"
          renderItem={(item) => (
            <List.Item>
              <NewsCard title={item.title} date={item.date} link={item.link} />
            </List.Item>
          )}
        />
      </NewsTabSection1>
    </NewsTabWrapper>
  )
}
