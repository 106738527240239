import * as React from 'react'
import { Tabs } from 'antd'
import queryString from 'query-string'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { ServiceWrapper, ServiceBanner, ServiceList, ServiceBox, TabTitle } from '../../styles/service.style'
import services from '../../json/services.json'

interface IServiceProps extends RouteComponentProps {}

const { TabPane } = Tabs
const tabs = [
  { key: 'marketing', tabtitle: 'Marketing', category: 'marketing' },
  { key: 'contents', tabtitle: 'Contents', category: 'contents' },
  { key: 'digital_platform', tabtitle: 'Digital Platform', category: 'digital' },
  { key: 'consulting', tabtitle: 'Consulting', category: 'consulting' },
]

const Service: React.FC<IServiceProps> = (props: IServiceProps) => {
  const history = useHistory()
  const { search } = useLocation()
  const query = queryString.parse(search)

  return (
    <ServiceWrapper>
      <ServiceBanner>
        <h2>Service</h2>
      </ServiceBanner>
      <ServiceList
        defaultActiveKey={query.tab ? query.tab.toString() : 'marketing'}
        activeKey={query.tab ? query.tab.toString() : 'marketing'}
        onTabClick={(tab) => {
          history.push({
            search: `?tab=${tab.toString()}`,
          })
        }}
      >
        {tabs.map((tab) => {
          return (
            <TabPane tab={tab.tabtitle} key={tab.key}>
              <TabTitle>{tab.tabtitle}</TabTitle>
              {services.map((service, i) => {
                return tab.category === service.category ? (
                  <ServiceBox
                    key={`serviceKey: ${i.toString()}`}
                    style={{ backgroundImage: `url( ${process.env.PUBLIC_URL + service.imgUrl} )` }}
                  >
                    <h2>{service.title}</h2>
                  </ServiceBox>
                ) : null
              })}
            </TabPane>
          )
        })}
      </ServiceList>
    </ServiceWrapper>
  )
}

export default Service
