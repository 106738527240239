import styled from 'styled-components'
import ntBanner from '../assets/img/newstab_banner.png'
import mobile_ntBanner from '../assets/img/mobile_newstab_banner.png'

export const NewsWrapper = styled.div`
  width: 100%;

  .ant-tabs-nav {
    position: absolute;
    top: 470px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 414px) {
      top: 250px;
      left: 0;
      transform: translateX(0);
      width: 100%;
      border-bottom: 1px solid #a3a3ae;
    }

    &::before,
    &::after {
      display: none;
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab {
    width: fit-content;
    margin: 0 70px;
    padding: 17px 0;

    @media (max-width: 768px) {
      margin: 0 24px;
    }
    @media (max-width: 414px) {
      margin: 0 20px;
    }

    &:hover {
      color: #a3a3ae;
    }
  }
  .ant-tabs-tab-btn {
    text-align: center;
    text-shadow: none;
    font-size: 18px;
    font-weight: 400;
    color: #a3a3ae;

    @media (max-width: 414px) {
      text-align: left;
      font-size: 14px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
    font-weight: 400;
    text-shadow: none;
    text-decoration: underline;
    border: none;
  }
`
export const NewsTabWrapper = styled.div`
  width: 100%;
`
export const NewsTabBanner = styled.div`
  width: 100%;
  height: 400px;
  background: url(${ntBanner}) no-repeat center;
  background-size: cover;

  @media (max-width: 414px) {
    height: 250px;
    background: url(${mobile_ntBanner}) no-repeat center;
    background-size: cover;
  }

  h2 {
    text-align: center;
    line-height: 420px;
    font-size: 45px;
    font-weight: bold;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 414px) {
      font-size: 30px;
      line-height: 280px;
    }
  }
`
export const TabTitle = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 230px;
  font-size: 35px;
  color: #111;
  font-weight: 400;

  @media (max-width: 414px) {
    padding-top: 107px;
    font-size: 26px;
  }
`
export const NewsTabSection1 = styled.div`
  .ant-list-items {
    display: flex;
    flex-wrap: wrap;
    gap: 150px 80px;

    @media (max-width: 1230px) {
      flex-direction: column;
      gap: 55px 0;
    }

    .ant-list-item {
      border-bottom: none;
      padding: 0;

      &:last-child {
        border-bottom: none !important;
      }
    }
  }

  .ant-pagination {
    margin-top: 100px;
    text-align: center;

    button {
      &:hover span {
        color: #000;
      }
      &:hover {
        border-color: #000;
      }
    }
  }
  .ant-pagination-item {
    &:hover a {
      color: #000;
    }
    &:hover {
      border-color: #000;
    }
  }
  .ant-pagination-item-active a {
    color: #000;
  }
  .ant-pagination-item-active {
    border-color: #000;
  }
`
export const PrMaterialTabWrapper = styled.div`
  width: 100%;
`
export const PrMaterialTabBanner = styled.div`
  width: 100%;
  height: 400px;
  background: url(${ntBanner}) no-repeat center;
  background-size: cover;

  @media (max-width: 414px) {
    height: 250px;
  }

  h2 {
    text-align: center;
    line-height: 420px;
    font-size: 45px;
    font-weight: bold;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 414px) {
      font-size: 30px;
      line-height: 280px;
    }
  }
`
export const SubTitle = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #111;
  font-weight: 400;

  @media (max-width: 414px) {
    font-size: 16px;
  }
`
export const PrMaterialTabSection1 = styled.div`
  &.wrap {
    max-width: 1100px;
  }
  .videoContainer {
    padding: 60px 0;
  }
`
