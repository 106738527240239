import * as React from 'react'
import {
  PrMaterialTabBanner,
  PrMaterialTabSection1,
  PrMaterialTabWrapper,
  SubTitle,
  TabTitle,
} from '../../styles/news.style'
import { CardWrapper } from '../../styles/card.style'
import brochure from '../../assets/img/brochure.png'
import ci from '../../assets/img/ci.png'
import VideoCard from '../../components/videoCard'
import { DefaultButton } from '../../components/button'

interface IPrMaterialTabProps {}

export default function PrMaterialTab(props: IPrMaterialTabProps) {
  return (
    <PrMaterialTabWrapper>
      <PrMaterialTabBanner>
        <h2>PR MATERIALS</h2>
      </PrMaterialTabBanner>
      <TabTitle>PR Materials</TabTitle>
      <SubTitle>Find out the latest news about PURPLESEED</SubTitle>
      <PrMaterialTabSection1 className="wrap">
        <CardWrapper>
          <div className="imgWrap">
            <img src={brochure} alt="이미지" />
          </div>
          <div className="txtWrap">
            <h3>Brochure</h3>
            <h2>퍼플시드 회사소개서</h2>
            <p>{`마케팅의 바다에 일으키는\n보랏빛 물결`}</p>
            <a
              href={`${process.env.PUBLIC_URL}/public_assets/pdf/purpleseed_brochure_2022.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              <DefaultButton text="다운로드" />
            </a>
          </div>
        </CardWrapper>
        <div className="videoContainer">
          <VideoCard
            link="https://www.youtube.com/watch?v=DAAIWj-LCa8"
            video="https://www.youtube.com/embed/DAAIWj-LCa8"
            subTitle="Video"
            title="퍼플시드 홍보 영상"
            descript={`퍼플시드는 고객의 니즈를 반영한\n창의적인 콘텐츠를 제작, 배포합니다.`}
            linkTxt="감상하기"
          />
        </div>
        <CardWrapper>
          <div className="imgWrap">
            <img src={ci} alt="이미지" />
          </div>
          <div className="txtWrap">
            <h3>CI/BI</h3>
            <h2>퍼플시드</h2>
            <p>{`차별성을 가진 보라, 가능성을 가진\n씨앗이란 의미를 담아\n간결하고 직관적인 디자인으로\n완성하였습니다.`}</p>
          </div>
        </CardWrapper>
      </PrMaterialTabSection1>
    </PrMaterialTabWrapper>
  )
}
