import * as React from 'react'
import { CeoTabWrapper, CeoTabBanner, TabTitle, CeoTabSection1 } from '../../styles/company.style'
import sign from '../../assets/img/ceo_signature.png'
import ceo from '../../assets/img/ceo_img.png'

export interface ICeoTabProps {}

export default function CeoTab(props: ICeoTabProps) {
  return (
    <CeoTabWrapper>
      <CeoTabBanner>
        <h2>CEO MESSAGE</h2>
      </CeoTabBanner>
      <TabTitle>CEO 인사말</TabTitle>
      <CeoTabSection1 className="wrap ceoTabWrap">
        <div>
          <div className="topParagrah">
            퍼플시드는 정직한 영혼이 있는 기업으로서 <br />
            언제나 고객과 든든한 파트너로 상생하기 위하여 <br />
            노력하겠습니다.
          </div>
          <div className="middleParagrah">
            <img src={sign} alt="임세란 대표님 서명" />
          </div>
          <div className="bottomParagrah">
            고객에게 최적화된 솔루션을 제공해 드리기 위하여 밤낮을 고민하고, <br />
            직접 발로 뛰며 실용적이고 합리적인 노하우를 쌓았습니다.
            <br />
            퍼플시드의 역량은 그만큼 향상 되었으며,
            <br />
            다양한 파트너들과 네트워크 인프라가 구축되어
            <br />
            차별화된 마케팅 솔루션을 제공해 드리기에 자신이 있습니다.
            <br />
            이러한 역량을 기반으로 고객과의 적극적이고 진심 어린 소통을 통하여
            <br />
            새로운 경험을 선사해 드리는 것이 퍼플시드의 목표입니다.
          </div>
        </div>
        <div>
          <img className="ceoImg" src={ceo} alt="임세란 대표님 사진" />
        </div>
      </CeoTabSection1>
    </CeoTabWrapper>
  )
}
