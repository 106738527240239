import * as React from 'react'
import { Tabs } from 'antd'
import queryString from 'query-string'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { CompanyWrapper } from '../../styles/company.style'
import CompanyTab from './CompanyTab'
import BrandTab from './BrandTab'
import CeoTab from './CeoTab'

interface ICompanyProps extends RouteComponentProps {}

const { TabPane } = Tabs

const Company: React.FC<ICompanyProps> = (props: ICompanyProps) => {
  const history = useHistory()
  const { search } = useLocation()
  const query = queryString.parse(search)

  return (
    <CompanyWrapper>
      <Tabs
        defaultActiveKey={query.tab ? query.tab.toString() : 'company'}
        activeKey={query.tab ? query.tab.toString() : 'company'}
        onTabClick={(tab) => {
          history.push({
            search: `?tab=${tab.toString()}`,
          })
        }}
      >
        <TabPane tab="회사 소개" key="company">
          <CompanyTab />
        </TabPane>
        <TabPane tab="브랜드 소개" key="brand">
          <BrandTab />
        </TabPane>
        <TabPane tab="CEO 인사말" key="ceo">
          <CeoTab />
        </TabPane>
      </Tabs>
    </CompanyWrapper>
  )
}

export default Company
