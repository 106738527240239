import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Collapse } from 'antd'
import { MobilePanel, MobileNavWrapper } from '../../styles/layout.style'

interface IMobileNavProps {
  drawerVisible: boolean
  mobileSubNavHandler: any
}

const { Panel } = Collapse

export default class MobileNav extends React.Component<IMobileNavProps> {
  constructor(props: IMobileNavProps) {
    super(props)
    this.state = {}
  }

  public render() {
    return (
      <MobilePanel
        title=""
        placement="right"
        onClose={this.props.mobileSubNavHandler}
        visible={this.props.drawerVisible}
      >
        <MobileNavWrapper>
          <Collapse defaultActiveKey="" ghost>
            <Panel header="COMPANY" key="1">
              <NavLink to="/company?tab=company" onClick={this.props.mobileSubNavHandler}>
                회사소개
              </NavLink>
              <NavLink to="/company?tab=brand" onClick={this.props.mobileSubNavHandler}>
                브랜드소개
              </NavLink>
              <NavLink to="/company?tab=ceo" onClick={this.props.mobileSubNavHandler}>
                ceo인사말
              </NavLink>
            </Panel>
            <Panel header="SERVICE" key="2">
              <NavLink to="/service?marketing" onClick={this.props.mobileSubNavHandler}>
                Marketing
              </NavLink>
              <NavLink to="/service?tab=contents" onClick={this.props.mobileSubNavHandler}>
                Contents
              </NavLink>
              <NavLink to="/service?tab=digital_platform" onClick={this.props.mobileSubNavHandler}>
                Digital Platform
              </NavLink>
              <NavLink to="/service?tab=consulting" onClick={this.props.mobileSubNavHandler}>
                Consulting
              </NavLink>
            </Panel>
            <Panel header="WORK" key="3">
              <NavLink to="/work?tab=1" onClick={this.props.mobileSubNavHandler}>
                All
              </NavLink>
              <NavLink to="/work?tab=2" onClick={this.props.mobileSubNavHandler}>
                공기업/정부기관
              </NavLink>
              <NavLink to="/work?tab=3" onClick={this.props.mobileSubNavHandler}>
                일반기업
              </NavLink>
            </Panel>
            <Panel header="NEWS" key="4">
              <NavLink to="/news?tab=news" onClick={this.props.mobileSubNavHandler}>
                NEWS
              </NavLink>
              <NavLink to="/news?tab=pr_material" onClick={this.props.mobileSubNavHandler}>
                PR MATERIALS
              </NavLink>
            </Panel>
            <Panel header="CONTACT US" key="5">
              <NavLink to="/contact" onClick={this.props.mobileSubNavHandler}>
                CONTACT US
              </NavLink>
            </Panel>
          </Collapse>
        </MobileNavWrapper>
      </MobilePanel>
    )
  }
}
