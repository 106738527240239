import * as React from 'react'
import styled from 'styled-components'
import google from '../assets/img/google_btn.png'
import apple from '../assets/img/apple_btn.svg'

interface IAppBtnProps {
  googleLink: string
  appleLink: string
}

export default class AppBtn extends React.Component<IAppBtnProps> {
  constructor(props: IAppBtnProps) {
    super(props)
    this.state = {}
  }
  public render() {
    return (
      <AppBtnWrapper>
        <div>APP DOWNLOAD</div>
        <a href={this.props.googleLink} rel="noreferrer" target="_blank">
          <img src={google} alt="구글 버튼" className="googleIcon" /> <span>Google Play</span>
        </a>
        <a href={this.props.appleLink} rel="noreferrer" target="_blank">
          <img src={apple} alt="애플 버튼" className="appleIcon" /> <span>App Store</span>
        </a>
      </AppBtnWrapper>
    )
  }
}

const AppBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 140px;

  @media (max-width: 858px) {
    flex-direction: column;
  }

  div {
    margin-right: 100px;
    font-size: 25px;
    font-weight: 700;

    @media (max-width: 858px) {
      margin-right: 0;
      margin-bottom: 45px;
    }
    @media (max-width: 414px) {
      font-size: 16px;
    }
  }

  a {
    width: 260px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;

    @media (max-width: 414px) {
      width: 200px;
      height: 46px;
      line-height: 46px;
    }

    span {
      font-size: 20px;
      font-weight: 600;
      color: #555;

      @media (max-width: 414px) {
        font-size: 15px;
      }
    }

    &:hover {
      border: 1px solid #a3a3ae;
    }

    &:first-of-type {
      margin-right: 20px;

      @media (max-width: 858px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    img {
      position: relative;

      &.googleIcon {
        width: 43px;
        top: -3px;
        left: 0;
      }

      &.appleIcon {
        width: 25px;
        top: -5px;
        left: -5px;

        @media (max-width: 414px) {
          width: 18px;
        }
      }
    }
  }
`
