import styled from 'styled-components'
import { Tabs } from 'antd'
import service_banner from '../assets/img/service_banner.png'

export const ServiceWrapper = styled.div`
  width: 100%;
`
export const ServiceBanner = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: url(${service_banner}) no-repeat center;
  background-size: cover;

  @media (max-width: 414px) {
    height: 250px;
  }

  h2 {
    text-align: center;
    line-height: 420px;
    font-size: 45px;
    font-weight: bold;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 414px) {
      font-size: 30px;
      line-height: 280px;
    }
  }
`
export const TabTitle = styled.div`
  width: 100%;
  text-align: center;
  padding: 100px 0;
  font-size: 35px;
  color: #111;
  font-weight: 400;

  @media (max-width: 414px) {
    padding: 0 0 30px;
    font-size: 26px;
  }
`
export const ServiceList = styled(Tabs)`
  width: 100%;
  padding: 100px 0 0;

  @media (max-width: 414px) {
    padding: 90px 0 0;
  }

  .ant-tabs-tabpane {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-tabs-nav {
    position: absolute;
    top: 470px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 414px) {
      top: 250px;
      left: 0;
      transform: translateX(0);
      width: 100%;
      border-bottom: 1px solid #a3a3ae;
    }

    &::before,
    &::after {
      display: none;
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab {
    width: fit-content;
    margin: 0 70px;
    padding: 17px 0;

    @media (max-width: 900px) {
      margin: 0 24px;
    }
    @media (max-width: 560px) {
      margin: 0 20px;
    }

    &:hover {
      color: #a3a3ae;
    }
  }
  .ant-tabs-tab-btn {
    text-align: center;
    text-shadow: none;
    font-size: 18px;
    font-weight: 400;
    color: #a3a3ae;

    @media (max-width: 560px) {
      text-align: left;
      font-size: 14px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
    font-weight: 400;
    text-shadow: none;
    text-decoration: underline;
    border: none;
  }
`
export const ServiceBox = styled.div`
  position: relative;
  width: 25%;
  padding-bottom: 25%;
  background-size: cover;

  @media (max-width: 560px) {
    width: 50%;
    padding-bottom: 50%;
  }

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
  }
`
