import * as React from 'react'
import { Tabs, List } from 'antd'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { WorkWrapper, WorkBanner, WorkLists, TabTitle } from '../../styles/work.style'
import queryString from 'query-string'
import WorkCard from '../../components/workCard'
import works from '../../json/works.json'
import { filter } from 'lodash'

interface IDetailHistory {
  id: string
  thumbnail: string
  client: string
  project: string
}

interface IWorkProps extends RouteComponentProps<IDetailHistory> {}

const { TabPane } = Tabs
const tabs = [
  { key: '1', tabtitle: 'All', category: 'all' },
  { key: '2', tabtitle: '공기업/정부기관', category: 'gov' },
  { key: '3', tabtitle: '일반기업', category: 'priv' },
]

const Work: React.FC<IWorkProps> = (props: IWorkProps) => {
  const history = useHistory()
  const { search } = useLocation()
  const query = queryString.parse(search)

  return (
    <WorkWrapper>
      <WorkBanner>
        <h2>Our Work</h2>
      </WorkBanner>
      <div className="wrap">
        <WorkLists
          defaultActiveKey={query.tab ? query.tab.toString() : '1'}
          activeKey={query.tab ? query.tab.toString() : '1'}
          onTabClick={(tab) => {
            history.push({
              search: `?tab=${tab.toString()}`,
            })
          }}
        >
          {tabs.map((tab) => {
            return (
              <TabPane tab={tab.tabtitle} key={tab.key}>
                <TabTitle>{tab.tabtitle}</TabTitle>
                <List
                  dataSource={tab.category === 'all' ? works : filter(works, { category: tab.category })}
                  pagination={{
                    onChange: (page) => {
                      history.push({
                        search: `?tab=${query.tab ? query.tab.toString() : '1'}&currentPage=${page.toString()}`,
                      })
                    },
                    defaultCurrent: query.currentPage ? Number(query.currentPage) : 1,
                    current: query.currentPage ? Number(query.currentPage) : 1,
                    pageSize: 9,
                  }}
                  itemLayout="horizontal"
                  renderItem={(work) => (
                    <List.Item>
                      <WorkCard id={work.id} thumbnail={work.thumbnail} client={work.client} project={work.project} />
                    </List.Item>
                  )}
                />
              </TabPane>
            )
          })}
        </WorkLists>
      </div>
    </WorkWrapper>
  )
}

export default Work
