import * as React from 'react'
import {
  CompanyTabWrapper,
  CompanyTabBanner,
  TabTitle,
  CompanyTabSection1,
  CompanyTabImgBox,
  CompanyTabSection2,
  CompanyTabMiddleBanner,
  HistoryWrapper,
  HistoryContent,
  CompanyTabBottomBanner,
  RecruitBanner,
} from '../../styles/company.style'
import histories from '../../json/histories.json'
import purple from '../../assets/img/company_purple.png'
import seed from '../../assets/img/company_seed.png'
import { WhiteButton } from '../../components/button'

export interface ICompanyTabProps {}
const lists = [
  { listTitle: '2022', category: '2022' },
  { listTitle: '2021', category: '2021' },
  { listTitle: '2020', category: '2020' },
  { listTitle: '2019', category: '2019' },
  { listTitle: '2018', category: '2018' },
]

export default function CompanyTab(props: ICompanyTabProps) {
  return (
    <CompanyTabWrapper>
      <CompanyTabBanner>
        <h2>COMPANY STORY</h2>
      </CompanyTabBanner>
      <TabTitle>회사 소개</TabTitle>
      <CompanyTabSection1 className="wrap">
        <h4>
          고객 성공의 씨앗들이 만드는
          <br />
          경이로운 보라색 숲.
        </h4>
        <p>
          퍼플시드는 남들과 똑같은 천편일률적 마케팅과 비효율을 지양합니다.
          <br />
          한정된 리소스 내에서 최대한 효과적인 마케팅 전략을 활용해, 나무 나이테처럼 지식 경험 자산을 쌓아 왔습니다.
          <br />
          세심한 고객 소통으로 이루어내는 높은 브랜드 이해 및 무엇보다 즐기면서 일하는 퍼플시드의 크리에이티브한
          <br />
          에너지의 씨앗이 고객님들의 성공에 든든한 버팀목이 되는 보랏빛 숲으로 자리하겠습니다.
        </p>
        <CompanyTabImgBox>
          <div>
            <div className="imgBox">
              <img src={purple} alt="퍼플 이미지" />
            </div>
            <h5>차별성을 가진 PURPLE</h5>
            <p>
              빨강의 힘과 파랑의 우아함이 공존하는 보라색.
              <br />
              진부한 마케팅을 지양하고 차별화된 마케팅 전략을 제안합니다.
            </p>
          </div>
          <div>
            <div className="imgBox">
              <img src={seed} alt="시드 이미지" />
            </div>
            <h5>가능성을 가진 SEED</h5>
            <p>
              고객의 든든한 숲이 되어드릴 씨앗.
              <br />
              고객과의 만남으로 움튼 씨앗을 통해 사업의 성장을
              <br />
              밑받침하는 숲이 되어드립니다.
            </p>
          </div>
        </CompanyTabImgBox>
      </CompanyTabSection1>
      <CompanyTabSection2>
        <div className="wrap">
          <h2>VISION</h2>
          <h3>
            VISION트렌드에 맞추어 고객의 다양한 요구와 소비자의 니즈를 반영하여
            <br />
            최적화된 마케팅 및 컨설팅 서비스를 제공합니다
          </h3>
          <div className="circleBoxWrapper">
            <div>
              <div className="circleBox">
                <h2>고객 이해</h2>
              </div>
              <h3>
                니즈 핵심 파악
                <br />
                실전적 최적화
                <br />
                솔루션
              </h3>
            </div>
            <div>
              <div className="circleBox">
                <h2>강력한 퍼포먼스</h2>
              </div>
              <h3>
                경험과 실력을 갖춘
                <br />
                전문가 그룹
              </h3>
            </div>
            <div>
              <div className="circleBox">
                <h2>크리에이티브 그 이상</h2>
              </div>
              <h3>
                트렌드에 맞춘
                <br />
                새로운 솔루션
              </h3>
            </div>
          </div>
        </div>
      </CompanyTabSection2>
      <CompanyTabMiddleBanner>
        <h2>MISSION</h2>
        <p>
          우리의 미션은 우리가 만드는 콘텐츠와 서비스를 통해
          <br />
          사용자에게 차별화된 마케팅 솔루션을 제시하고 진심 어린 소통으로 새로운 경험을 제공하는 것입니다.
        </p>
      </CompanyTabMiddleBanner>
      <HistoryWrapper className="wrap">
        <h2>HISTORY</h2>
        {lists.map((list, i) => {
          return (
            <div key={`historyKey: ${i.toString()}`} className="historyList">
              <div>{list.listTitle}</div>
              <div>
                {histories.map((history) => {
                  return list.category === history.category ? (
                    <HistoryContent>
                      <div>{history.date}</div>
                      <div>{history.content}</div>
                    </HistoryContent>
                  ) : null
                })}
              </div>
            </div>
          )
        })}
      </HistoryWrapper>
      <CompanyTabBottomBanner>
        <h2>GOOD WILL, GOOD BEHAVIOR</h2>
        <p>선한 의지로 일하고, 선한 행동을 하는 것이 퍼플시드의 철학입니다.</p>
      </CompanyTabBottomBanner>
      <RecruitBanner>
        <h2>Recruit</h2>
        <p>
          퍼플시드는 고객의 다양한 요구와 소비자의 니즈를 반영하여 최적화된 마케팅 및 컨설팅 서비스를 제공합니다.
          <br />
          퍼플시드와 함께 성장하고 싶다면, 지금 바로 지원해주세요!
        </p>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://purpleseed.notion.site/We-are-Purpleseed-37ab1b0d2aa146dbaa248926413ed17b"
        >
          <WhiteButton text="RECRUIT" />
        </a>
      </RecruitBanner>
    </CompanyTabWrapper>
  )
}
