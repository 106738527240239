import * as React from 'react'
import { FooterWrapper } from '../styles/layout.style'
import logo_wh from '../assets/img/logo_wh.png'
import facebook_ico from '../assets/img/facebook.png'
import insta_ico from '../assets/img/insta.png'
import blog_ico from '../assets/img/blog.png'
import youtube_ico from '../assets/img/youtube.png'

export interface IFooterProps {}

export default class footer extends React.Component<IFooterProps> {
  public render() {
    return (
      <FooterWrapper>
        <div className="wrap fWrap">
          <div className="footer">
            <img src={logo_wh} alt="퍼플시드 로고" />
            <ul>
              <li>회사명 | (주)뉴플러스기획</li>
              <li>대표자명 | 임세란</li>
              <li>Tel | 02.6080.0920</li>
              <li>E-mail | purpleseed@purpleseed.co.kr</li>
              <li>사업자등록번호 | 864-81-01703</li>
              <li>주소 | 서울특별시 강남구 언주로 714, 4F (서울시 강남구 논현동 106-6 401호)</li>
            </ul>
            <p>Copyright © 2021 NEWPLUS. All Rights Reserved.</p>
          </div>
          <div className="sns">
            <a rel="noreferrer" href="https://www.facebook.com/purpleseed1/" target="_blank">
              <img src={facebook_ico} alt="페이스북" />
            </a>
            <a rel="noreferrer" href="https://www.instagram.com/purpleseed1/" target="_blank">
              <img src={insta_ico} alt="인스타그램" />
            </a>
            <a rel="noreferrer" href="https://blog.naver.com/purpleseed1" target="_blank">
              <img src={blog_ico} alt="블로그" />
            </a>
            <a rel="noreferrer" href="https://www.youtube.com/channel/UCXgt0Tn6zS0yT8EtixJl4ww" target="_blank">
              <img src={youtube_ico} alt="유튜브" />
            </a>
          </div>
        </div>
      </FooterWrapper>
    )
  }
}
