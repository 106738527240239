import * as React from 'react'
import Header from './layout/header'
import Footer from './layout/footer'

declare global {
  interface Window {
    kakao: any
    dataLayer: any
  }
}

export default class App extends React.Component {
  children: any

  public render() {
    return (
      <>
        <Header />
        {this.children}
        <Footer />
      </>
    )
  }
}
