import * as React from 'react'
import { Tabs } from 'antd'
import queryString from 'query-string'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { NewsWrapper } from '../../styles/news.style'
import NewsTab from './NewsTab'
import PrMaterialTab from './PrMaterialTab'

interface INewsProps extends RouteComponentProps {}

const { TabPane } = Tabs

const News: React.FC<INewsProps> = (props: INewsProps) => {
  const history = useHistory()
  const { search } = useLocation()
  const query = queryString.parse(search)

  return (
    <NewsWrapper>
      <Tabs
        defaultActiveKey={query.tab ? query.tab.toString() : 'news'}
        activeKey={query.tab ? query.tab.toString() : 'news'}
        onTabClick={(tab) => {
          history.push({
            search: `?tab=${tab.toString()}`,
          })
        }}
      >
        <TabPane tab="NEWS" key="news">
          <NewsTab />
        </TabPane>
        <TabPane tab="PR MATERIALS" key="pr_material">
          <PrMaterialTab />
        </TabPane>
      </Tabs>
    </NewsWrapper>
  )
}

export default News
