import styled from 'styled-components'
import bBanner from '../assets/img/company_bottom_banner.png'
import mobilebBanner from '../assets/img/company_banner_bottom_m.png'
import ctBanner from '../assets/img/companytab_banner.png'
import btBanner from '../assets/img/brandtab_banner.png'
import ceotBanner from '../assets/img/ceotab_banner.png'
import brand1 from '../assets/img/brand_img01.png'
import brand2 from '../assets/img/brand_img02.png'
import brand3 from '../assets/img/brand_img03.png'
import mission from '../assets/img/company_banner_mission.png'
import mobileMission from '../assets/img/company_banner_mission_m.png'

export const CompanyWrapper = styled.div`
  width: 100%;

  .ant-tabs-nav {
    position: absolute;
    top: 470px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 414px) {
      top: 250px;
      left: 0;
      transform: translateX(0);
      width: 100%;
      border-bottom: 1px solid #a3a3ae;
    }

    &::before,
    &::after {
      display: none;
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab {
    width: fit-content;
    margin: 0 70px;
    padding: 17px 0;

    @media (max-width: 768px) {
      margin: 0 24px;
    }
    @media (max-width: 414px) {
      margin: 0 20px;
    }

    &:hover {
      color: #a3a3ae;
    }
  }
  .ant-tabs-tab-btn {
    text-align: center;
    text-shadow: none;
    font-size: 18px;
    font-weight: 400;
    color: #a3a3ae;

    @media (max-width: 414px) {
      text-align: left;
      font-size: 14px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
    font-weight: 400;
    text-shadow: none;
    text-decoration: underline;
    border: none;
  }
`
export const CompanyTabWrapper = styled.div`
  width: 100%;
`
export const CompanyTabBanner = styled.div`
  width: 100%;
  height: 400px;
  background: url(${ctBanner}) no-repeat center;
  background-size: cover;

  @media (max-width: 414px) {
    height: 250px;
  }

  h2 {
    text-align: center;
    line-height: 420px;
    font-size: 45px;
    font-weight: bold;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 414px) {
      font-size: 30px;
      line-height: 280px;
    }
  }
`
export const TabTitle = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 230px;
  font-size: 35px;
  color: #111;
  font-weight: 400;

  @media (max-width: 414px) {
    padding-top: 107px;
    font-size: 26px;
  }
`
export const CompanyTabSection1 = styled.div`
  h4 {
    margin-bottom: 49px;
    text-align: center;
    font-size: 45px;
    font-weight: 600;
    line-height: 1.5;

    @media (max-width: 768px) {
      font-size: 40px;
    }
    @media (max-width: 414px) {
      margin-bottom: 20px;
      font-size: 28px;
    }
  }
  p {
    margin-bottom: 68px;
    text-align: center;
    line-height: 1.7;
    font-size: 18px;
  }
`
export const CompanyTabImgBox = styled.div`
  display: flex;
  gap: 100px;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 414px) {
    flex-direction: column;
    gap: 45px;
  }

  .imgBox {
    max-height: 500px;
    margin-bottom: 45px;
    overflow: hidden;

    @media (max-width: 414px) {
      margin-bottom: 35px;
    }

    img {
      max-width: 100%;
      transition: transform 0.25s ease-in;

      &:hover {
        transform: scale(120%, 120%);
      }
    }
  }

  h5 {
    font-size: 30px;
    font-weight: 600;

    @media (max-width: 414px) {
      font-size: 20px;
    }
  }
  p {
    text-align: start;
    font-size: 18px;

    @media (max-width: 414px) {
      font-size: 14px;
    }
  }
`
export const CompanyTabSection2 = styled.div`
  background: #f8f8fa;

  .wrap {
    max-width: 1100px;

    @media (max-width: 414px) {
      padding: 60px 20px;
    }
  }

  h2 {
    text-align: center;
    font-size: 35px;
    font-weight: 600;

    @media (max-width: 414px) {
      font-size: 24px;
    }
  }
  h3 {
    margin-bottom: 83px;
    text-align: center;
    font-size: 22px;
    font-weight: normal;

    @media (max-width: 414px) {
      margin-bottom: 53px;
      font-size: 16px;
    }
  }

  .circleBoxWrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .circleBox {
      position: relative;
      width: 300px;
      height: 300px;
      margin-bottom: 55px;
      border-radius: 300px;
      border: 1px solid #707070;

      @media (max-width: 1100px) {
        width: 200px;
        height: 200px;
      }

      @media (max-width: 768px) {
        margin: 0 auto 20px;
      }

      h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
        margin-bottom: 0;
        text-align: center;
        font-size: 28px;
        font-weight: 400;

        @media (max-width: 1100px) {
          font-size: 18px;
        }
      }
    }

    h3 {
      margin-bottom: 0;
      text-align: center;
      font-size: 22px;
      line-height: 35px;
      color: #000;

      @media (max-width: 768px) {
        margin-bottom: 39px;
      }
      @media (max-width: 414px) {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }
`
export const CompanyTabMiddleBanner = styled.div`
  width: 100%;
  padding: 140px 0;
  background: url(${mission}) no-repeat center;
  background-size: cover;

  @media (max-width: 414px) {
    padding: 70px 0;
    background: url(${mobileMission}) no-repeat center;
    background-size: cover;
  }

  h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    color: #fff;

    @media (max-width: 414px) {
      font-size: 24px;
    }
  }
  p {
    text-align: center;
    font-size: 22px;
    line-height: 40px;
    color: #fff;

    @media (max-width: 414px) {
      font-size: 16px;
      line-height: 25px;
    }
  }
`
export const HistoryWrapper = styled.div`
  &.wrap {
    max-width: 1100px;
    padding: 60px 20px;
  }
  h2 {
    text-align: center;
    color: #000;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    letter-spacing: 1px;
  }

  .historyList {
    display: flex;
    padding: 53px 0;
    border-bottom: 1px solid #ddd;

    &:last-of-type {
      border-bottom: none;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
    @media (max-width: 414px) {
      &:first-of-type {
        padding-top: 0;
      }
    }

    & > div {
      &:first-of-type {
        width: 123px;
        font-size: 24px;
        font-weight: 900;
        color: #5e366a;

        @media (max-width: 768px) {
          width: 100%;
          text-align: left;
          margin-bottom: 30px;
        }
      }
    }
  }
`
export const HistoryContent = styled.div`
  display: flex;

  @media (max-width: 280px) {
    flex-direction: column;
  }

  & > div {
    white-space: pre-wrap;
    margin-bottom: 20px;

    &:first-of-type {
      width: 86px;
      font-size: 16px;
      font-weight: 700;
      color: #222;
    }

    &:last-of-type {
      font-size: 16px;
      color: #666;

      @media (max-width: 1140px) {
        width: 500px;
      }
      @media (max-width: 320px) {
        width: 100%;
      }
    }
  }
`
export const CompanyTabBottomBanner = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url(${bBanner}) no-repeat center;
  background-size: cover;

  @media (max-width: 414px) {
    height: 250px;
    background: url(${mobilebBanner}) no-repeat center;
    background-size: cover;
  }
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 35px;
    font-weight: 600;
    color: #fff;

    @media (max-width: 414px) {
      padding: 0 20px;
      font-size: 24px;
    }
  }
  p {
    text-align: center;
    font-size: 22px;
    color: #fff;

    @media (max-width: 414px) {
      padding: 0 20px;
      font-size: 16px;
    }
  }
`
export const RecruitBanner = styled.div`
  width: 100%;
  height: 375px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #641d87;

  h2 {
    text-align: center;
    margin-bottom: 23px;
    font-size: 35px;
    font-weight: 700;
    color: #fff;

    @media (max-width: 414px) {
      padding: 0 20px;
      margin-bottom: 20px;
      font-size: 24px;
    }
  }
  p {
    margin-bottom: 0;
    text-align: center;
    font-size: 22px;
    line-height: 40px;
    color: #fff;

    @media (max-width: 414px) {
      padding: 0 20px;
      font-size: 16px;
      line-height: 25px;
    }
  }
  a {
    display: block;
    margin: 0 auto;
  }
`
export const BrandTabWrapper = styled.div`
  width: 100%;
`
export const BrandTabBanner = styled.div`
  width: 100%;
  height: 400px;
  background: url(${btBanner}) no-repeat center;
  background-size: cover;

  @media (max-width: 414px) {
    height: 250px;
  }

  h2 {
    text-align: center;
    line-height: 420px;
    font-size: 45px;
    font-weight: bold;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 414px) {
      font-size: 30px;
      line-height: 280px;
    }
  }
`
export const BrandTabSection1 = styled.div`
  width: 100%;

  .wrap {
    padding-bottom: 0;

    h3 {
      text-align: center;
      font-size: 45px;
      font-weight: 600;

      @media (max-width: 768px) {
        font-size: 38px;
      }
      @media (max-width: 414px) {
        white-space: pre-wrap;
        font-size: 24px;
      }
    }
    h4 {
      text-align: center;
      font-size: 22px;
      font-weight: 400;

      @media (max-width: 414px) {
        white-space: pre-wrap;
        font-size: 16px;
      }
    }
  }

  img {
    width: 100%;

    @media (max-width: 414px) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 200%;
    }
  }
`
export const BrandTabSection2 = styled.div`
  &.brandSec2Wrap {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }
    @media (max-width: 414px) {
      padding: 50px 20px;
    }

    img {
      width: 350px;
      height: 32px;
      margin-right: 80px;

      @media (max-width: 768px) {
        margin-bottom: 45px;
        margin-right: 0;
      }
      @media (max-width: 414px) {
        width: 200px;
        height: 19px;
      }
    }
    div {
      font-size: 22px;
      line-height: 1.75;

      @media (max-width: 414px) {
        font-size: 16px;
      }
    }
  }
`
export const BrandBoxWrapper = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 650px) {
    flex-direction: column;
    padding: 0 20px 55px;
  }
`
export const BrandBox = styled.div`
  width: 33.3%;
  padding: 25% 50px 50px 50px;

  @media (max-width: 1450px) {
    padding: 20% 20px 20px 20px;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding-top: 70%;
  }

  &.box1 {
    background: url(${brand1}) no-repeat center;
    background-size: cover;
  }
  &.box2 {
    background: url(${brand2}) no-repeat center;
    background-size: cover;

    h5 {
      color: #000;
    }
    p {
      color: #000;
    }
  }
  &.box3 {
    background: url(${brand3}) no-repeat center;
    background-size: cover;
  }

  h5 {
    font-size: 30px;
    font-weight: 700;
    color: #fff;

    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
  p {
    font-size: 18px;
    color: #fff;
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`
export const CeoTabWrapper = styled.div`
  width: 100%;
`
export const CeoTabBanner = styled.div`
  width: 100%;
  height: 400px;
  background: url(${ceotBanner}) no-repeat center;
  background-size: cover;

  @media (max-width: 414px) {
    height: 250px;
  }

  h2 {
    text-align: center;
    line-height: 420px;
    font-size: 45px;
    font-weight: bold;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;

    @media (max-width: 414px) {
      font-size: 30px;
      line-height: 280px;
    }
  }
`
export const CeoTabSection1 = styled.div`
  width: 100%;

  &.ceoTabWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1160px) {
      flex-direction: column-reverse;
    }
  }

  .topParagrah {
    font-size: 28px;
    color: #111;
    font-weight: 400;

    @media (max-width: 1160px) {
      margin-top: 37px;
    }

    @media (max-width: 414px) {
      font-size: 24px;
    }
  }
  .middleParagrah {
    text-align: end;
    margin-bottom: 45px;

    @media (max-width: 414px) {
      img {
        width: 200px;
      }
    }
  }
  .bottomParagrah {
    font-size: 22px;
    font-weight: 400;
    color: #111;

    @media (max-width: 414px) {
      margin-bottom: 54px;
      font-size: 16px;
    }
  }

  .ceoImg {
    @media (max-width: 630px) {
      width: 100%;
    }
  }
`
