import * as React from 'react'
import { CardWrapper } from '../styles/card.style'
import { DefaultButton } from './button'

export interface IvideoCardProps {
  subTitle: string
  title: string
  descript: string
  linkTxt: string
  video: string
  link: string
}

export default class videoCard extends React.Component<IvideoCardProps> {
  constructor(props: IvideoCardProps) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <CardWrapper className="cardRight">
        <div className="txtWrap">
          <h3>{this.props.subTitle}</h3>
          <h2>{this.props.title}</h2>
          <p>{this.props.descript}</p>
          <a target="_blank" rel="noreferrer" href={this.props.link}>
            <DefaultButton text={this.props.linkTxt} />
          </a>
        </div>
        <div className="videoWrap">
          <iframe title="YouTube video player" src={this.props.video} frameBorder="0" />
        </div>
      </CardWrapper>
    )
  }
}
