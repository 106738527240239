import styled from 'styled-components'

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  &.cardRight {
    @media (max-width: 1000px) {
      flex-direction: column-reverse;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  .imgWrap {
    width: 785px;
    height: auto;
    overflow: hidden;

    @media (max-width: 1000px) {
      width: auto;
      text-align: center;
    }

    img {
      transition: transform 0.25s ease-in;

      @media (max-width: 1000px) {
        width: 100%;
      }

      &:hover {
        transform: scale(120%, 120%);
      }
    }
  }

  .videoWrap {
    width: 785px;
    height: 550px;

    @media (max-width: 850px) {
      width: 100%;
      height: 480px;
    }
    @media (max-width: 530px) {
      width: 100%;
      height: 320px;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .txtWrap {
    padding: 0 40px;

    @media (max-width: 1000px) {
      width: 100%;
      padding: 20px 0 50px;
    }

    h3 {
      text-align: start;
      font-size: 16px;
      font-weight: bold;
      line-height: 26px;
      color: #641d87;
      letter-spacing: 1px;

      @media (max-width: 1000px) {
        text-align: center;
      }
    }
    h2 {
      white-space: pre-wrap;
      text-align: start;
      letter-spacing: 1px;
      font-size: 26px;
      font-weight: bold;
      line-height: 32px;
      margin: 0 0 30px;
      color: #332e54;

      @media (max-width: 1000px) {
        text-align: center;
        margin-bottom: 10px;
      }

      @media (max-width: 414px) {
        font-size: 20px;
      }
    }
    p {
      white-space: pre-wrap;
      text-align: start;
      line-height: 24px;
      margin: 0 0 50px;
      font-size: 16px;
      color: #504b6e;

      @media (max-width: 1000px) {
        margin: 0 0 20px;
        text-align: center;
      }
    }
    a {
      display: block;

      @media (max-width: 1000px) {
        text-align: center;
      }
    }
  }
`

export const WorkCardWrapper = styled.div`
  width: 380px;
  height: 500px;
  margin: 0 auto;

  @media (max-width: 1280px) {
    width: 320px;
  }
  @media (max-width: 1030px) {
    width: 280px;
    margin: 30px auto;
  }
  @media (max-width: 640px) {
    width: 100%;
    height: 100%;
    margin: 20px auto;
  }

  a {
    display: block;
    width: 380px
    height: inherit;

    .thumbnailWrap{
      width: 380px;
      height: 380px;
      overflow: hidden;

      @media (max-width: 1280px) {
        width: 320px;
        height: 320px;
      }
      @media (max-width: 1030px) {
        width: 280px;
        height: 280px;
      }
      @media (max-width: 640px) {
        width: 100%;
        height: auto;
      }

      img {
        width: 380px;
        height: 380px;
        transition: all 0.2s ease-in;

        @media (max-width: 1280px) {
          width: 320px;
          height: 320px;
        }
        @media (max-width: 1030px) {
          width: 280px;
          height: 280px;
        }
        @media (max-width: 640px) {
          width: inherit;
          height: auto;
        }
      }
    }
    
    h2 {
      margin: 33px 0 8px;
      white-space: pre-wrap;
      font-size: 20px;
      font-weight: 400;
      color: #111;

      @media (max-width: 414px){
        margin: 20px 0 5px;
        font-size: 16px;
      }
    }

    h3 {
      white-space: pre-wrap;
      font-size: 26px;
      font-weight: 400;
      color: #111;

      @media (max-width: 414px){
        font-size: 22px;
      }
    }

    &:hover img{
      transform: scale(120%, 120%);
    }
  }
`
export const WorkDetailCardWrapper = styled.div`
  h2 {
    white-space: pre-wrap;
    font-size: 45px;
    font-weight: 700;
    color: #111;

    @media (max-width: 414px) {
      font-size: 26px;
    }
  }

  .subTitle {
    display: flex;
    white-space: pre-wrap;
    @media (max-width: 414px) {
      flex-direction: column;
    }

    h3 {
      margin-right: 40px;
      font-size: 28px;
      font-weight: 700;

      @media (max-width: 414px) {
        font-size: 16px;
      }

      span {
        margin-left: 40px;
        font-weight: 400;

        @media (max-width: 414px) {
          margin-left: 35px;
        }
      }
    }
  }

  p {
    font-size: 28px;

    @media (max-width: 414px) {
      font-size: 16px;
    }
  }

  .videoWrapper {
    height: 756px;
    padding-top: 0;

    @media (max-width: 768px) {
      height: 480px;
    }
    @media (max-width: 414px) {
      height: 235px;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .btnWrapper {
    width: fit-content;
    margin: 20px auto 0;
  }
`
