import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { PcNavWrapper } from '../../styles/layout.style'
import PcSubNav from './pcSubNav'

interface IPcNavProps {
  mouseEnterHandler: any
  mouseLeaveHandler: any
  navClickHandler: any
}

export default class PcNav extends React.Component<IPcNavProps> {
  constructor(props: IPcNavProps) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <PcNavWrapper>
        <div className="pcNav" onMouseEnter={this.props.mouseEnterHandler} onMouseLeave={this.props.mouseLeaveHandler}>
          <NavLink to="/company?tab=company" onClick={this.props.navClickHandler}>
            COMPANY
          </NavLink>
          <NavLink to="/service?tab=marketing" onClick={this.props.navClickHandler}>
            SERVICE
          </NavLink>
          <NavLink to="/work?tab=1" onClick={this.props.navClickHandler}>
            WORK
          </NavLink>
          <NavLink to="/news?tab=news" onClick={this.props.navClickHandler}>
            NEWS
          </NavLink>
          <NavLink to="/contact" onClick={this.props.navClickHandler}>
            CONTACT US
          </NavLink>
        </div>
        <div
          className="pcSubNav"
          onMouseEnter={this.props.mouseEnterHandler}
          onMouseLeave={this.props.mouseLeaveHandler}
        >
          <PcSubNav clickHandler={this.props.navClickHandler} />
        </div>
      </PcNavWrapper>
    )
  }
}
