import * as React from 'react'
import {
  BrandTabWrapper,
  BrandTabBanner,
  TabTitle,
  BrandTabSection1,
  BrandTabSection2,
  BrandBoxWrapper,
  BrandBox,
} from '../../styles/company.style'
import bg from '../../assets/img/brandTab_bg.png'
import logo from '../../assets/img/logo_purple.png'

export interface IBrandTabProps {}

export default function BrandTab(props: IBrandTabProps) {
  return (
    <BrandTabWrapper>
      <BrandTabBanner>
        <h2>BRAND STORY</h2>
      </BrandTabBanner>
      <TabTitle>브랜드 소개</TabTitle>
      <BrandTabSection1>
        <div className="wrap">
          <h3>{`WE MAKE THE TRACKS\n FOR THE GOAL`}</h3>
          <h4>{`퍼플시드가 목표를 위해, 성공을 위해\n 함께 트랙을 만듭니다.`}</h4>
        </div>
        <img src={bg} alt="퍼플시드 배경 이미지" />
      </BrandTabSection1>
      <BrandTabSection2 className="wrap brandSec2Wrap">
        <img src={logo} alt="퍼플시드 배경 이미지" />
        <div>
          퍼플시드의 BI 심볼은 트랙의 상징을 담아 제작하였습니다. <br />
          페이스메이커가 좋은 기록을 내기 위해 함께 트랙을 달리며 경기의 흐름을 서포트하듯
          <br />
          퍼플시드는 고객을 위해 서비스와 브랜드 환경에 맞는 최적의 솔루션을 제공하여
          <br />
          고객의 비즈니스를 서포트합니다.
        </div>
      </BrandTabSection2>
      <BrandBoxWrapper>
        <BrandBox className="box1">
          <h5>Run the Track</h5>
          <p>
            좋은 기록으로 완주할 수 있도록 <br />
            퍼플시드가 함께 트랙을 달립니다.
          </p>
        </BrandBox>
        <BrandBox className="box2">
          <h5>For the Goal</h5>
          <p>
            고객의 목표를 위해, 성공을 위해 <br />
            퍼플시드가 함께 합니다.
          </p>
        </BrandBox>
        <BrandBox className="box3">
          <h5>Make a Track</h5>
          <p>
            퍼플시드는 비즈니스의 방향과 속도에 맞는 <br />
            고유의 트랙을 만들어갑니다.
          </p>
        </BrandBox>
      </BrandBoxWrapper>
    </BrandTabWrapper>
  )
}
