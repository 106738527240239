import { observer } from 'mobx-react'
import * as React from 'react'
import { match } from 'react-router-dom'
import { WorkDetailWrapper, WorkDetailBanner } from '../../styles/work.style'
import works from '../../json/works.json'

import * as H from 'history'
import WorkDetailCard from '../../components/workDetailCard'

export interface RouteComponentProps<P> {
  match: match<P>
  location: H.Location
  history: H.History
  staticContext?: any
}
interface MatchParams {
  id: string
}
interface MatchProps extends RouteComponentProps<MatchParams> {}

const Detail: React.FC<MatchProps> = ({ match }: MatchProps) => {
  return (
    <WorkDetailWrapper>
      <WorkDetailBanner>
        <h2>Our Work</h2>
      </WorkDetailBanner>
      <div className="detailWrap">
        {works.map((work, i) => {
          return work.id === match.params.id ? (
            <WorkDetailCard
              key={`worksKey: ${i.toString()}`}
              pcTopBanner={work.pcTopBanner}
              mbTopBanner={work.mbTopBanner}
              title={work.title}
              client={work.client}
              launch={work.launch}
              descript={work.descript}
              pcDetailImg={work.pcDetailImg}
              mbDetailImg={work.mbDetailImg}
              category={work.category}
              system={work.system}
              video={work.video}
              video2={work.video2}
              googleLink={work.googleLink}
              appleLink={work.appleLink}
              webLink={work.webLink}
            />
          ) : null
        })}
      </div>
    </WorkDetailWrapper>
  )
}

export default observer(Detail)
